<template>
  <div class="group">
    <div class="card">
      <img :src="'/images/mundouniversitariologo.png'" class="card-img-top" alt="..." />
      <div class="card-body">
        <h5 class="card-title">Mundo Universitario</h5>
        <p class="card-text">
          <em>
            Conoce más acerca de nuestra comunidad y la forma de integración
            en el mundo profesional a los lideres del mañana.
          </em>
        </p>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-4">
          <button class="btn btn-outline-primary" type="button">
            Conocer más
          </button>
        </div>
      </div>
    </div>
    <div class="card">
      <img :src="'/images/profile-shop.jpg'" class="card-img-top" alt="..." />
      <div class="card-body">
        <h5 class="card-title">Marketplace Universitario</h5>
        <p class="card-text">
          <em>
            Conoce nuestra tienda universitaria con millones de productos
          </em>
        </p>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <button class="btn btn-outline-primary" type="button">
            Comprar
          </button>
        </div>
      </div>
    </div>
    <div class="card">
      <img :src="'/images/profile-chat.jpg'" class="card-img-top" alt="..." />
      <div class="card-body">
        <h5 class="card-title">Chat Universitario</h5>
        <p class="card-text">
          <em>
            Conoce personas con tus mismos intereses y crea tu comunidad
          </em>
        </p>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <button class="btn btn-outline-primary" type="button">
            Empieza a Chatear
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style land="scss" scoped>
@import "../../styles/about.scss";
</style>